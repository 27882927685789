import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import get from 'lodash.get';


import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import { HtmlEntities } from '../utils/helpers';

const PortfolioTemplate = (props) => {
    const {data, pageContext} = props;
    const project             = data.wordpressWpPortfolio;
    const featured_image      = `${data.site.siteMetadata.siteUrl}${project.featured_media.localFile.childImageSharp.original.src}`;
    const yoast_meta          = project.yoast_meta;

    const projectGallery = get(project, 'acf.project_gallery', []);

    const linkNext = pageContext.nextSlug ? pageContext.nextSlug : '';
    const linkPrev = pageContext.prevSlug ? pageContext.prevSlug : '';

    //TODO: Replace it with more robust solution
    //very hacky way to restore the proper uploads URL before we find a way to serve them other way
    const content = project.content.replace(/https:\/\/lowgravity.pl\/core\/uploads/g, 'https://api.lowgravity.pl/core/uploads');

    return (
        <Layout>
            <PageHeader
                heading={project.title}
                subHeading={project.acf.project_subheading}
                pageTitle={`${HtmlEntities.decode(project.title)} - LowGravity.pl`}
                seoMetaData={{...yoast_meta, featured_image}}
            />
            <div className="portfolio-single-component">
                <div className="component-wrapper">
                    <div className="project-content">
                        <div dangerouslySetInnerHTML={{__html: content}}></div>
                        <ul className="project-gallery">
                            {projectGallery.map(({localFile}) => (
                                <li key={localFile.id}>
                                    <Img fluid={localFile.childImageSharp.fluid} />
                                </li>
                            ))}
                        </ul>
                        {project.acf.project_link ? (
                            <a
                                className="button"
                                href={project.acf.project_link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View project online
                            </a>
                        ) : null}
                    </div>
                    <div className="related-projects">
                        {linkPrev === '' ? null : (
                            <div className="prev-project">
                                <h4>Previous project</h4>
                                <Link to={linkPrev}>
                                    <div className="thumb-wrapper">
                                        <Img fluid={data.prevPost.featured_media.localFile.childImageSharp.fluid} />
                                    </div>
                                    <p dangerouslySetInnerHTML={{__html: data.prevPost.title}}></p>
                                </Link>
                            </div>
                        )}
                        {linkNext === '' ? null : (
                            <div className="next-project">
                                <h4>Next project</h4>
                                <Link to={linkNext}>
                                    <div className="thumb-wrapper">
                                        <Img fluid={data.nextPost.featured_media.localFile.childImageSharp.fluid} />
                                    </div>
                                    <p dangerouslySetInnerHTML={{__html: data.nextPost.title}}></p>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PortfolioTemplate;

export const pageQuery = graphql`
    query($id: String!, $prevID: String, $nextID: String) {
        wordpressWpPortfolio(id: { eq: $id }) {
            title
            content
            featured_media {
                localFile {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
            }
            acf {
                project_subheading
                project_link
                project_gallery {
                    localFile {
                        id
                        childImageSharp {
                            fluid(maxWidth: 953) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                    }
                }
            }
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
                yoast_wpseo_canonical
            }
        }
        prevPost: wordpressWpPortfolio(id: { eq: $prevID }) {
            title
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 405){
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
        nextPost: wordpressWpPortfolio(id: { eq: $nextID }) {
            title
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 405){
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;
